<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings" :readonly="$authorised('con/library/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/library/access', 'read')">

			No settings available.

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			settings: true,
			model: {
				library: {
					sync: {
						enabled: false,
						username: ''
					}
				}
			}
		}

	},

	validations: {
		model: {
			library: {
				sync: {}
			}
		}
	}

}

</script>

<style scoped>

</style>